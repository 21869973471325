import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { i18nVue } from 'laravel-vue-i18n';
import * as Sentry from '@sentry/vue';
import { createSentryPiniaPlugin } from '@sentry/vue';
import { MotionPlugin } from '@vueuse/motion';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '../css/app.css';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/index.js';
import './bootstrap.js';
import AppLayout from './Layouts/AppLayout.vue';
import { isInjectedCode } from './sentry/isInjectedCode.js';

createInertiaApp({
    progress: {
        color: '#024047',
    },
    async resolve(name) {
        const { default: component } = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'));
        component.layout ??= AppLayout;
        return component;
    },
    setup({ el, App, props, plugin }) {
        const pinia = createPinia();

        pinia.use(createSentryPiniaPlugin());

        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(i18nVue, {
                lang: props.initialPage.props.locale,
                fallbackLang: 'de',
                fallbackMissingTranslations: true,
                resolve(lang) {
                    return props.initialPage.props.translations[lang] ?? {};
                },
                onLoad() {
                    if (el && el.__vue_app__) {
                        return;
                    }

                    // Mount app after translations are loaded
                    // https://github.com/xiCO2k/laravel-vue-i18n/issues/193#issuecomment-2631779753
                    app.mount(el);
                },
            })
            .use(MotionPlugin)
            .use(ZiggyVue)
            .use(FloatingVue);

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            ReplaysOnErrorSampleRate: 1,
            environment: import.meta.env.VITE_SENTRY_ENV,

            beforeSend(event) {
                if (isInjectedCode(event)) {
                    return null;
                }

                return event;
            },

            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],
        });
    },
});

// Init datalayer for gtm
globalThis.dataLayer ||= [];
