import { I18n, trans } from 'laravel-vue-i18n';
import { reactiveComputed } from '@vueuse/core';

export function useI18n() {
    const translations = I18n.getSharedInstance().activeMessages || {};

    /**
     * @param {string} prefix
     * @returns {import('vue').UnwrapNestedRefs<Record<string, string>>}
     */
    const getTranslationsByPrefix = prefix => reactiveComputed(() => {
        const normalizedPrefix = prefix.endsWith('.') ? prefix : `${prefix}.`;

        const entries = Object.entries(translations)
            .filter(([key]) => key.startsWith(normalizedPrefix))
            .map(([key, value]) => [key.slice(normalizedPrefix.length), value]);

        return Object.fromEntries(entries);
    });

    /**
     * @param {string} key
     * @param {string} defaultValue
     * @returns {string}
     */
    const transWithDefault = (key, defaultValue) => {
        const translated = trans(key);
        return translated === key ? defaultValue : translated;
    };

    return {
        getTranslationsByPrefix,
        transWithDefault,
    };
}
