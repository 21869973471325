// https://github.com/getsentry/sentry-javascript/issues/3147
export function isInjectedCode(event) {
    const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

    if (!frames || frames.length === 0) {
        return false;
    }

    const lastFrame = frames.at(-1);

    if (typeof lastFrame.filename !== 'string') {
        return false;
    }

    if (lastFrame.filename.includes(' line ') && lastFrame.filename.includes('> injectedScript')) {
        return true;
    }

    if (lastFrame.filename.startsWith(globalThis.location.origin)) {
        return false;
    }

    return true;
}
