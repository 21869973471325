<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { GlobeEuropeAfricaIcon } from '@heroicons/vue/24/solid/index.js';
import { useI18n } from '@/Composables/i18n.js';
import JetDropdownLink from '@/Components/DropdownLink.vue';
import JetDropdown from '@/Components/Dropdown.vue';

const page = usePage();
const locale = computed(() => page.props.locale);
const { getTranslationsByPrefix } = useI18n();
</script>

<template>
    <div class="relative inline-flex ml-6">
        <JetDropdown
            class="inline-flex"
            align="right"
            width="32"
        >
            <template #trigger>
                <span class="inline-flex">
                    <button
                        type="button"
                        class="inline-flex items-center font-semibold text-sm hover:text-sunset-orange-500 focus:text-sunset-orange-500 focus:outline-none transition-colors"
                        :aria-label="$t('components.languageSwitch.switch')"
                    >
                        <GlobeEuropeAfricaIcon
                            class="w-5 h-5 mr-1.5 opacity-75"
                            aria-hidden="true"
                        />

                        {{ locale.toUpperCase() }}
                    </button>
                </span>
            </template>

            <template #content>
                <div class="block text-xs text-deep-teal-500 purple-theme:text-purple-500 px-3 pt-2 pb-1">
                    {{ $t('components.languageSwitch.switch') }}
                </div>

                <div class="font-normal">
                    <JetDropdownLink
                        v-for="(language, key) in getTranslationsByPrefix('enums.language')"
                        :key
                        as="a"
                        :href="`/${key !== 'de' ? key : ''}`"
                        :active="key === locale"
                    >
                        {{ language }}
                    </JetDropdownLink>
                </div>
            </template>
        </JetDropdown>
    </div>
</template>
