<script setup>
import { router, Link, usePage } from '@inertiajs/vue3';
import {
    UserCircleIcon,
    ArrowRightEndOnRectangleIcon,
    ArrowLeftEndOnRectangleIcon,
} from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import { useNavigationStore } from '@/stores/navigation.js';
import JetApplicationMark from '@/Components/ApplicationMark.vue';
import JetDropdown from '@/Components/Dropdown.vue';
import JetDropdownLink from '@/Components/DropdownLink.vue';
import Topbar from '@/Layouts/Partials/Topbar.vue';
import MainNavigation from '@/Layouts/Partials/MainNavigation.vue';
import LanguageSwitcher from '@/Layouts/Partials/LanguageSwitcher.vue';
import OpenSearch from '@/Layouts/Partials/OpenSearch.vue';

const page = usePage();
const homeUrl = computed(() => page.props.homeUrl);

const logout = () => router.post(route('logout'));
const store = useNavigationStore();
</script>

<template>
    <div class="relative py-3 lg:py-1.5 lg:pt-4 lg:pb-0">
        <div class="hidden lg:flex justify-end text-sm container px-4 mx-auto">
            <Topbar />
        </div>

        <div class="flex justify-between lg:items-end lg:space-x-4 container mx-auto px-4">
            <!-- Logo -->
            <Link
                class="text-white focus:text-sunset-orange-500 focus:outline-none lg:mb-4"
                :href="homeUrl"
                aria-label="Home"
            >
                <JetApplicationMark class="block h-auto w-[4.375rem] lg:w-[7.5rem]" />
            </Link>

            <div class="hidden lg:flex lg:items-center">
                <!-- Main Navigation -->
                <MainNavigation />

                <!-- Auth/Profile Dropdown -->
                <div class="relative inline-flex ml-6">
                    <JetDropdown
                        class="inline-flex"
                        width="48"
                    >
                        <template #trigger>
                            <span class="inline-flex">
                                <button
                                    type="button"
                                    class="inline-flex items-center font-semibold text-lg text-golden-fizz-500 hover:text-sunset-orange-500 focus:text-sunset-orange-500 focus:outline-none transition-colors"
                                >
                                    <UserCircleIcon
                                        class="shrink-0 h-6 w-6 mr-1.5"
                                        aria-hidden="true"
                                    />

                                    <div class="whitespace-nowrap">
                                        {{ $t('components.navigation.profile.main') }}
                                    </div>
                                </button>
                            </span>
                        </template>

                        <template #content>
                            <div class="block text-xs text-deep-teal-500 purple-theme:text-purple-500 px-3 pt-2 pb-1">
                                {{ $t('components.navigation.profile.main') }}
                            </div>

                            <template v-if="$page.props.auth.user">
                                <JetDropdownLink
                                    :href="route('dashboard')"
                                    :active="route().current('dashboard')"
                                >
                                    {{ $t('components.navigation.profile.dashboard') }}
                                </JetDropdownLink>
                                <JetDropdownLink
                                    :href="route('event-orders.index')"
                                    :active="route().current('event-orders.index')"
                                >
                                    {{ $t('components.navigation.profile.orders') }}
                                </JetDropdownLink>
                                <JetDropdownLink
                                    :href="route('basic-data.show')"
                                    :active="route().current('basic-data.show')"
                                >
                                    {{ $t('components.navigation.profile.basicData') }}
                                </JetDropdownLink>
                                <JetDropdownLink
                                    :href="route('profile.show')"
                                    :active="route().current('profile.show')"
                                >
                                    {{ $t('components.navigation.profile.account') }}
                                </JetDropdownLink>
                                <JetDropdownLink
                                    v-if="$page.props.can.apiTokens"
                                    :href="route('api-tokens.index')"
                                    :active="route().current('api-tokens.index')"
                                >
                                    {{ $t('components.navigation.profile.apiTokens') }}
                                </JetDropdownLink>

                                <div class="border-t border-gray-100" />

                                <!-- Authentication -->
                                <form @submit.prevent="logout">
                                    <JetDropdownLink
                                        as="button"
                                        :with-icon="true"
                                    >
                                        <ArrowRightEndOnRectangleIcon
                                            class="shrink-0 h-5 w-5 mr-1.5"
                                            aria-hidden="true"
                                        />

                                        {{ $t('components.navigation.profile.logout') }}
                                    </JetDropdownLink>
                                </form>
                            </template>
                            <template v-else>
                                <JetDropdownLink
                                    :href="route('register')"
                                >
                                    {{ $t('components.navigation.profile.register') }}
                                </JetDropdownLink>
                                <JetDropdownLink
                                    :href="route('login')"
                                    :with-icon="true"
                                >
                                    <ArrowLeftEndOnRectangleIcon
                                        class="shrink-0 h-5 w-5 mr-1.5"
                                        aria-hidden="true"
                                    />

                                    {{ $t('components.navigation.profile.login') }}
                                </JetDropdownLink>
                            </template>
                        </template>
                    </JetDropdown>
                </div>
            </div>

            <!-- Mobile: search, language switcher & hamburger -->
            <div class="flex lg:hidden items-center space-x-6">
                <OpenSearch />

                <LanguageSwitcher />

                <button
                    class="inline-flex items-center justify-center p-2 -mr-1 rounded-md text-white focus:outline-none focus:ring-1 focus:ring-sunset-orange-500 transition-colors"
                    @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
                >
                    <svg
                        class="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
