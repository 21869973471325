<template>
    <div ref="autocompleteContainer" />
</template>

<script setup lang="jsx">
import { autocomplete } from '@algolia/autocomplete-js';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { meilisearchAutocompleteClient, getMeilisearchResults } from '@meilisearch/autocomplete-client';
import '@algolia/autocomplete-theme-classic';
import { computed, onMounted, onUnmounted, ref, h as createElement, Fragment, render } from 'vue';
import { BookOpenIcon, CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { trans } from 'laravel-vue-i18n';
import { useEventListener, useMutationObserver } from '@vueuse/core';
import { usePage } from '@inertiajs/vue3';
import Item from '@/Components/Search/Item.vue';
import ItemHeader from '@/Components/Search/ItemHeader.vue';
import NoResults from '@/Components/Search/NoResults.vue';
import { useSearchStore } from '@/stores/search.js';

const searchStore = useSearchStore();

const page = usePage();

const props = defineProps({
    host: {
        type: String,
        default: import.meta.env.VITE_MEILISEARCH_SEARCH_HOST,
    },
    searchKey: {
        type: String,
        default: import.meta.env.VITE_MEILISEARCH_SEARCH_KEY,
    },
    environment: {
        type: String,
        required: true,
    },
    locale: {
        type: String,
        required: true,
    },
    siteName: {
        type: String,
        required: true,
    },
});

const searchClient = meilisearchAutocompleteClient({
    url: props.host,
    apiKey: props.searchKey,
});

const autocompleteContainer = ref(null);
const autocompleteInstance = ref(null);
const autocompleteCore = ref(null);

const open = () => {
    autocompleteInstance.value.setIsOpen(true);
    searchStore.open();
};

const close = () => {
    autocompleteInstance.value.setIsOpen(false);
    searchStore.close();
};

const theme = computed(() => page.props.theme);

onMounted(() => {
    searchStore.$subscribe((mutation, state) => {
        if (state.isOpen) {
            open();
        } else {
            close();
        }
    });

    useEventListener(document, 'keydown', event => {
        if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
            if (document.body.classList.contains('aa-Detached')) {
                close();
            } else {
                open();
            }

            event.preventDefault();
        }
    });

    useMutationObserver(
        document.body,
        () => {
            const isSearchOpen = document.body.classList.contains('aa-Detached');

            if (!isSearchOpen) {
                searchStore.close();
            }
        },
        { attributes: true, attributeFilter: ['class'] },
    );

    const options = {
        translations: {
            submitButtonTitle: trans('components.autocompleteSearch.submitButton'),
            clearButtonTitle: trans('components.autocompleteSearch.clearButton'),
        },
        openOnFocus: true,
        detachedMediaQuery: '',
        container: autocompleteContainer.value,
        classNames: {
            detachedOverlay: theme.value,
        },
        placeholder: trans('components.autocompleteSearch.placeholder'),
        renderer: { createElement, Fragment, render },
        renderNoResults({ state, render }, root) {
            render(<NoResults state={state} text={trans('components.autocompleteSearch.noResultsFor')} />, root);
        },
        getSources({ query }) {
            return [
                {
                    sourceId: 'statamic_articles',
                    getItemUrl({ item }) {
                        return item.uri;
                    },
                    getItems() {
                        return getMeilisearchResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: `${props.environment}_statamic_articles_${props.siteName}`,
                                    query,
                                },
                            ],
                        });
                    },
                    templates: {
                        header() {
                            return (
                                <ItemHeader
                                    headline={trans('components.autocompleteSearch.articles.headline')}
                                    cta={trans('components.autocompleteSearch.articles.cta')}
                                    href={route('articles.search.index', { query })}
                                    onCloseSearch={() => close()}
                                />
                            );
                        },
                        item({ item, components }) {
                            return (
                                <Item
                                    item={item}
                                    components={components}
                                    icon={BookOpenIcon}
                                    descriptionAttribute={'teaser'}
                                />
                            );
                        },
                    },
                },
                {
                    sourceId: 'program_packages',
                    getItemUrl({ item }) {
                        return item.uri;
                    },
                    getItems() {
                        const filters = computed(() =>
                            props.locale === 'de'
                                ? '(event_format_language="de" OR event_format_language="en")'
                                : 'event_format_language="en"',
                        );

                        return getMeilisearchResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: `${props.environment}_program_packages`,
                                    query,
                                    params: {
                                        filters: filters.value,
                                    },
                                },
                            ],
                        });
                    },
                    templates: {
                        header() {
                            return (
                                <ItemHeader
                                    headline={trans('components.autocompleteSearch.events.headline')}
                                    cta={trans('components.autocompleteSearch.events.cta')}
                                    href={route('events.search.index', { query })}
                                    onCloseSearch={() => close()}
                                />
                            );
                        },
                        item({ item, components }) {
                            return (
                                <Item
                                    item={item}
                                    components={components}
                                    icon={CalendarDaysIcon}
                                    descriptionAttribute={'teaser'}
                                />
                            );
                        },
                    },
                },
                {
                    sourceId: 'statamic_pages',
                    getItemUrl({ item }) {
                        return item.uri;
                    },
                    getItems() {
                        return getMeilisearchResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: `${props.environment}_statamic_pages_${props.siteName}`,
                                    query,
                                },
                            ],
                        });
                    },
                    templates: {
                        header() {
                            return (
                                <ItemHeader
                                    headline={trans('components.autocompleteSearch.pages.headline')}
                                />
                            );
                        },
                        item({ item, components }) {
                            return (
                                <Item
                                    item={item}
                                    components={components}
                                />
                            );
                        },
                    },
                },
            ];
        },
    };

    autocompleteCore.value = createAutocomplete(options);

    autocompleteInstance.value = autocomplete({
        ...options,
    });
});

onUnmounted(() => {
    autocompleteInstance.value?.destroy();
});
</script>

<style>
.aa-Autocomplete {
    @apply hidden invisible;
}

.aa-List {
    @apply space-y-3;
}

.aa-Item {
    @apply p-0;
}

.aa-PanelLayout {
    @apply p-4 space-y-8;
}

.aa-DetachedContainer--modal .aa-PanelLayout {
    @apply px-4 pb-6;
}

.aa-DetachedOverlay {
    @apply backdrop-blur-sm;

    --aa-panel-border-color-rgb: 229, 231, 235;
    --aa-primary-color-rgb: 3, 85, 95;
    --aa-input-border-color-alpha: 0;
    --aa-overlay-color-rgb: 0, 0, 0;
    --aa-overlay-color-alpha: 0.6;
    --aa-muted-color-rgb: 156, 163, 175;
    --aa-muted-color-alpha: 1;
    --aa-search-input-height: 2rem;
    --aa-text-color-rgb: 31, 41, 55;
}

.aa-DetachedOverlay.purple-theme {
    --aa-primary-color-rgb: 41, 5, 140;
    --aa-text-color-rgb: 15, 1, 61;
}

.aa-Form {
    @apply border-none;
}

.aa-Form:focus-within {
    @apply shadow-none !important;
}

.aa-List > .aa-Item > a:focus-visible {
    @apply outline-none ring-2 ring-deep-teal-700 purple-theme:ring-purple-700;
}

.aa-SubmitButton,
.aa-ClearButton,
.aa-DetachedFormContainer > .aa-DetachedCancelButton {
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-deep-teal-700 purple-theme:focus-visible:ring-purple-700;
}

.aa-SubmitButton {
    @apply focus-visible:rounded-l-sm;
}

.aa-ClearButton {
    @apply focus-visible:rounded-r-sm;
}

.aa-DetachedFormContainer {
    @apply py-3;
}

.aa-DetachedFormContainer > .aa-DetachedCancelButton {
    @apply bg-no-repeat bg-center text-transparent my-auto mx-2;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%231F2937' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
    text-indent: -9999px;
}

.aa-DetachedFormContainer > .aa-DetachedCancelButton:hover {
    @apply shadow-none opacity-80;
}

.aa-Label > .aa-SubmitButton {
    @apply bg-no-repeat bg-center w-6 mr-4 ml-2 p-0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%231F2937' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z' /%3E%3C/svg%3E%0A");
}

svg.aa-SubmitIcon {
    @apply hidden invisible;
}
</style>
